import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { CircularProgress, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { ROUTES } from './routes'

const CoachingPage = lazy(() => import('../pages/CoachingPage'))
const CoachesPage = lazy(() => import('../pages/Coaches'))
const SchedulePage = lazy(() => import('../pages/Schedule'))

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.SCHEDULE}
                            component={SchedulePage}
                        />
                        <ProtectedRoute
                            path={ROUTES.COACHES}
                            component={CoachesPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.COACHING}
                            component={CoachingPage}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
